<template>
    <div>
        <template v-if="linkType === 'copy'">
            <action-button
                v-clipboard:copy="buildUrl(item)"
                v-clipboard:success="onCopy"
                icon="copy" />
        </template>
        <template v-else>
            {{ buildUrl(item) }}
        </template>
    </div>
</template>

<script>
import ActionButton from '@/components/globals/ActionButton';

export default {
    components: {
        ActionButton
    },
    props: {
        item: {
            type: Object,
            required: true
        },
        linkType: {
            type: String,
            default: 'link'
        }
    },
    methods: {
        buildUrl(invite) {
            return window.location.origin + this.$router.resolve({ name: 'reseller-sign-up', query: { id: invite?.uuid } }).href;
        },
        onCopy() {
            this.$flash('Link copied to clipboard!', 'green');
        }
    }
};
</script>